/*===============================================================================*/
/* Bootstrap 4 */
/*===============================================================================*/
@import "bootstrap/scss/bootstrap-reboot";
@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap/scss/bootstrap";

/*===============================================================================*/
/* Slick Slider */
/*===============================================================================*/
@import "slick/slick.scss";
@import "slick/slick-theme.scss";

/*===============================================================================*/
/* AOS */
/*===============================================================================*/
@import "aos/scss/aos.scss";